import React from 'react';
import PartialNav from './PartialNav';
import { Link } from 'react-router-dom';
// import SocialLink from './SocialLink';
import Logo from '../../../assets/images/Logo-Kanindo.svg';
import { Row, Col, Navbar } from 'react-bootstrap';
import { FiMail, FiSmartphone } from 'react-icons/fi';
import SocialLink from "./SocialLink";

class Header extends React.Component {
  render() {
    return (
      <>
        <header className="header-area-2 header-area-4">
          <div className="header-top pl-30 pr-30">
            <div className="container-fluid">
              <Row className="align-items-center">
                <Col lg="6">
                <div className="header-left-side text-center text-sm-left">
                  <ul>
                    <li>
                      <a
                        href="mailto:info@kanindoconsulting.com"
                        target="__blank"
                      >
                        <FiMail />
                        info@kanindoconsulting.com
                      </a>
                    </li>
                    {/*<li>
                      <a
                        href="https://wa.me/6282128627646?text=Hello%20Kanindo%20Visas%2C"
                        target="__blank"
                      >
                        <FiSmartphone />
                        +62 821-2862-7646
                      </a>
                    </li>*/}
                  </ul>
                </div>
              </Col>
                <div className="col-lg-6">
                  <div className="header-right-social text-center text-sm-right">
                    <SocialLink/>
                  </div>
                </div>
            </Row>
            </div>
          </div>
          <div className="header-nav">
            <div className="navigation">
              <Navbar expand="lg" className={`transparent-color`}>
                <Navbar.Brand as={Link} to={'/'}>
                  <img
                    src={Logo}
                    alt="logo"
                    style={{ width: '250px' }}
                  />
                </Navbar.Brand>
                <Navbar.Toggle style={{ marginRight: '0px' }}>
                  <span className="toggler-icon" />
                  <span className="toggler-icon" />
                  <span className="toggler-icon" />
                </Navbar.Toggle>{' '}
                {/* navbar toggler */}
                <Navbar.Collapse id="navbarSupportedContent">
                  <PartialNav />
                </Navbar.Collapse>{' '}
                {/* navbar collapse */}
              </Navbar>
            </div>{' '}
            {/* navigation */}
          </div>
        </header>
      </>
    );
  }
}

export default Header;
