import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTwitter,
    faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import {FiSmartphone} from "react-icons/fi";


class SocialLink extends Component {
    render() { 
        return ( 
            <ul>
                {/*<li>
                    <a href="https://facebook.com" target="__blank">
                        <FontAwesomeIcon icon={faFacebookF}/>
                    </a>
                </li>*/}
                <li>
                    <a
                        href="https://wa.me/6282128627646?text=Hello%20Kanindo%20Visas%2C"
                        target="__blank"
                    >
                        <FiSmartphone />
                        +62 821-2862-7646
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/kanindovisa/" target="__blank">
                        <FontAwesomeIcon icon={faTwitter}/>
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/kanindovisa/" target="__blank">
                        <FontAwesomeIcon icon={faInstagram}/>
                    </a>
                </li>
                {/*<li>
                    <a href="https://linkedin.com" target="__blank">
                        <FontAwesomeIcon icon={faLinkedinIn}/>

                    </a>
                </li>
                <li>
                    <a href="https://youtube.com" target="__blank">
                        <FontAwesomeIcon icon={faYoutube}/>
                    </a>
                </li>*/}
            </ul>
         );
    }
}
 
export default SocialLink;