import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <footer
          className="footer-area footer-area-2 footer-area-1 bg_cover"
          style={{
            backgroundImage: "url(assets/images/footer-bg.jpg)",
          }}
        >
          <div className="footer-overlay">
            <Container className="position-relative">
              <Row>
                <Col lg="4" md="7">
                  <div className="widget-item-2 widget-item-3">
                    <h4 className="title">Contact Us</h4>
                    <a
                      href="https://wa.me/6282128627646?text=Hello%20Kanindo%20Visas%2C"
                      target="_blank"
                      style={{ color: "white" }}
                    >
                      <span style={{ fontWeight: 400, color: "#c2d1e2" }}>
                        Phone:
                      </span>{" "}
                      +62 821-2862-7646
                    </a>
                    <a
                      style={{
                        marginTop: 0,
                        paddingTop: 0,
                        color: "white",
                      }}
                      href="mailto:info@kanindoconsulting.com"
                      target="__blank"
                    >
                      <p style={{ marginTop: 0, paddingTop: 0 }}>
                        Email:{" "}
                        <span style={{ color: "#ffffff" }}>
                          info@kanindoconsulting.com
                        </span>
                      </p>
                    </a>
                    <h4 className="title" style={{ marginTop: "1.5rem" }}>
                      Our Policy
                    </h4>
                    <Link to={"/terms-conditions"}>
                      <p style={{ marginTop: 0, paddingTop: 0 }}>
                        Term & Conditions
                      </p>
                    </Link>
                    <br />
                    <Link to={"/privacy-policy"}>
                      <p style={{ marginTop: 0, paddingTop: 0 }}>
                        Privacy Policy
                      </p>
                    </Link>
                  </div>{" "}
                  {/* widget item 3 */}
                </Col>
                <Col lg="4" md="6">
                  <div className="widget-item-2 widget-item-3">
                    <h4 className="title">Working Hours</h4>
                    <ul>
                      <li>Monday - Friday: 10:00 - 17:00</li>
                      <li>Tuesday: 10:00 - 17:00</li>
                      <li>Wednesday: 10:00 - 17:00</li>
                      <li>Thursday: 10:00 - 17:00</li>
                      <li>Friday: 10:00 - 17:00</li>
                      <li>Saturday: Closed</li>
                      <li>Sunday: Closed</li>
                    </ul>
                  </div>{" "}
                  {/* widget item 3 */}
                </Col>
                <Col lg="4" md="6">
                  <div style={{ borderRadius: "border-radius: 8px" }}>
                    <iframe
                      title="Our Office"
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7933.4160609922765!2d106.81815!3d-6.169836!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f5d62697031f%3A0x259cad06ec4e2729!2sJl.%20Suryopranoto%20No.11f%2C%20RT.14%2FRW.8%2C%20Petojo%20Utara%2C%20Kecamatan%20Gambir%2C%20Kota%20Jakarta%20Pusat%2C%20Daerah%20Khusus%20Ibukota%20Jakarta%2010130%2C%20Indonesia!5e0!3m2!1sen!2sus!4v1688638883080!5m2!1sen!2sus"
                      width="400"
                      height="250"
                      className="border-0"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </Col>
              </Row>{" "}
              {/* row */}
              <Row>
                <Col lg="12">
                  <div className="footer-copyright">
                    <p>
                      Copyright <span>Kanindo</span> - 2023
                    </p>
                  </div>{" "}
                  {/* footer copyright */}
                </Col>
              </Row>{" "}
              {/* row */}
            </Container>{" "}
            {/* container */}
          </div>
        </footer>
        {/*====== BACK TO TOP ======*/}
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
        />
        <a
          href="https://wa.me/6282128627646?text=Hello%20Kanindo%20Visas%2C"
          className="float"
          target="_blank"
        >
          <i className="fa fa-whatsapp my-float"></i>
        </a>
        {/*====== BACK TO TOP ======*/}
      </React.Fragment>
    );
  }
}

export default Footer;
